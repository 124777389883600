// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import snackbar from "./snackbar";
import clientReducer from "./clients"; // Import the client slice

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  clients: clientReducer,
});

export default reducers;
