// ==============================|| OVERRIDES - BUTTON BASE ||============================== //

export default function ButtonBase() {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
  };
}
