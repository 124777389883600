// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";

import { useEffect, useState, useRef } from "react";

import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import CustomAccordionSkeleton from "components/CustomAccordionSkeleton";
import CustomAccordion from "components/CustomAccordian";
// ==============================|| STANDARD LIST||============================== //

const QuestionStandardList = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const standardName = useRef(localStorage.getItem("standardName"));
  const [getQuestions, setQuestions] = useState();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getQuestionList = async () => {
    try {
      let reqObj = {
        standard: standardName.current,
      };
      let uri = url.GET_STD_QUESTION;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result.response) setQuestions(result.result.data);
    } catch (error) {
      console.error("GET_ALL_QUESTION Function Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const DynamicAccordion = () => {
    return (
      <>
        {isLoading ? (
          <CustomAccordionSkeleton />
        ) : (
          Object.entries(sections)?.map(
            ([sectionTitle, sectionData], sectionIndex) => (
              <>
                <CustomAccordion
                  key={`accordion-${sectionIndex}`}
                  disabled={isLoading}
                  expanded={expanded === `accordion-${sectionIndex}`}
                  onChange={handleAccordionChange(`accordion-${sectionIndex}`)}
                  summary={<Typography variant="h6">{sectionTitle}</Typography>}
                  details={Object.values(sectionData?.questions)?.map(
                    (questionData, index) => (
                      <Accordion>
                        <AccordionSummary>
                          <Typography>
                            {`${index + 1}) ${
                              questionData?.question?.questionContent
                            }`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>Hello</AccordionDetails>
                      </Accordion>
                    )
                  )}
                />
              </>
            )
          )
        )}
      </>
    );
  };

  const sections = {};

  // Loop through the data array
  getQuestions?.forEach((item) => {
    const { section } = item.question;

    // If the section doesn't exist in sections object, create it
    if (!sections[section]) {
      sections[section] = { questions: {} };
    }
    // Add the question to its corresponding section using _id as key
    sections[section].questions[item._id] = item;
  });

  useEffect(() => {
    getQuestionList();
  }, []);

  return (
    <MainCard title="Questions List">
      <DynamicAccordion />
    </MainCard>
  );
};

export default QuestionStandardList;
