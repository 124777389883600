// ==============================|| OVERRIDES - DRAWER ||============================== //

export default function Drawer() {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
        },
      },
    },
  };
}
