import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import * as url from "../../../../../../src/services/url_helper";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// project-imports
import ProfileTab from "./ProfileTab";
import SettingTab from "./SettingTab";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import IconButton from "components/@extended/IconButton";
import useAuth from "hooks/useAuth";
import { ThemeMode } from "config";

// assets
import avatar1 from "assets/images/users/avatar-6.png";
import { Setting2, Profile, Logout } from "iconsax-react";
import { ApiServices } from "services/apiServices";
import constant from "services/constant";

// tab panel wrapper
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: 1 }}
    >
      {value === index && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const { logout, user } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: "",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  console.log(user, "user");
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [profileAvatar, setProfileAvatar] = useState(undefined);
  const [profChane, setProfChange] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  console.log("selectedImage", selectedImage);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProfile = async () => {
    console.log(">>called");
    const result = await ApiServices.callServiceGet(
      url.GET_PROFILE + user?._id
    );
    setProfileAvatar(result?.result?.user?.profileImage);
    // setProfileAvatar("//standardImage/1719571936215_download%20(2).jpg");
    console.log(result, "deiekjj");
  };
  useEffect(() => {
    getProfile();
  }, [user]);
  {
    profileAvatar !== undefined && console.log(profileAvatar, "bfdbb");
  }

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append("profileName", selectedImage?.name);
    formData.append("profile", selectedImage);
    let uri = url.UPDATE_PROFILE;
    ApiServices.callServicePutWithFormData(uri + user?._id, formData)
      .then((result) => {
        console.log(result, "fgdscjdsfkj");
        setProfChange(false);
        getProfile();
      })
      .catch((error) => {
        console.log("error to post profile", error);
      });
  };

  const handleChangeProfile = async (e) => {
    setSelectedImage(e.target.files?.[0]);
  };
  console.log(user?._id, "ifd");
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          borderRadius: 1,
          "&:hover": {
            bgcolor:
              theme.palette.mode === ThemeMode.DARK
                ? "secondary.light"
                : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar
          alt="profile user"
          src={
            profileAvatar !== undefined
              ? constant.IMG_URL + profileAvatar
              : avatar1
          }
        />
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar
                            alt="profile user"
                            src={
                              profileAvatar !== undefined
                                ? constant.IMG_URL + profileAvatar
                                : avatar1
                            }
                          />
                          <Stack>
                            <Typography variant="subtitle1">
                              {user?.name}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                              Profile
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    {/* {profChane ? (
                      <Box>
                        <TextField
                          type="file"
                          id="change-avtar"
                          ref={fileInputRef}
                          variant="outlined"
                          onChange={(e) => handleChangeProfile(e)}
                        />
                        <Button
                          onClick={() => {
                            handleUpdateProfile();
                          }}
                        >
                          Update
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        onClick={() => {
                          setProfChange(true);
                        }}
                      >
                        Change Profile
                      </Button>
                    )} */}
                  </CardContent>
                  <Divider />
                  <ProfileTab handleLogout={handleLogout} />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfilePage;
