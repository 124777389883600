import React, { useState, useEffect } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import CustomTooltip from "components/reusables/CustomTooltip";
import useConfig from "hooks/useConfig";
import { ThemeMode as ThemeModeEnum } from "config";

const ThemeMode = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();
  const [isDarkMode, setIsDarkMode] = useState(mode === ThemeModeEnum.DARK);

  useEffect(() => {
    setIsDarkMode(mode === ThemeModeEnum.DARK);
  }, [mode]);

  const handleToggle = () => {
    const newMode = isDarkMode ? ThemeModeEnum.LIGHT : ThemeModeEnum.DARK;
    onChangeMode(newMode);
    setIsDarkMode(!isDarkMode);
  };

  return (
    <CustomTooltip title={isDarkMode ? "Light Mode" : "Dark Mode"}>
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          color="secondary"
          aria-label="toggle theme mode"
          onClick={handleToggle}
          size="large"
          sx={{
            color: "secondary.main",
            p: 1,
          }}
        >
          {isDarkMode ? (
            <LightModeIcon fontWeight="bold" />
          ) : (
            <ModeNightIcon fontWeight="bold" />
          )}
        </IconButton>
      </Box>
    </CustomTooltip>
  );
};

export default ThemeMode;
