// material-ui
import { Box, useMediaQuery } from "@mui/material";

import Profile from "./Profile";
import Notification from "./Notification";

import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";
import { MenuOrientation } from "config";
import ThemeMode from "./ThemeMode";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ width: "100%", ml: 1 }} />
      <ThemeMode />
      <Notification />
      <Profile />
    </>
  );
};

export default HeaderContent;
