import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Dashboard from "pages/dashboard/dashboard";
import Theme from "pages/apps/Standards/QMSSet/QualityManual/Theme";
import QuestionStandardList from "pages/apps/Standards/QuestionStandardList";

const MaintenanceError = Loadable(
  lazy(() => import("pages/maintenance/error/404"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/error/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon/coming-soon"))
);

const AppClientList = Loadable(lazy(() => import("pages/apps/client/list")));
const AppClientProductList = Loadable(
  lazy(() => import("pages/apps/client/clientProduct"))
);
const AppProductList = Loadable(lazy(() => import("pages/apps/product/list")));
const AppProductDocument = Loadable(
  lazy(() => import("pages/apps/product/document"))
);
const AppCategoryList = Loadable(
  lazy(() => import("pages/apps/category/list"))
);
const AppTemplateList = Loadable(
  lazy(() => import("pages/apps/templates/list"))
);
const AppTemplateAdd = Loadable(
  lazy(() => import("pages/apps/templates/addAndUpdate"))
);
const AppTemplateEdit = Loadable(
  lazy(() => import("pages/apps/templates/addAndUpdate"))
);
const AppTemplateView = Loadable(
  lazy(() => import("pages/apps/templates/view"))
);
const AppQuestionList = Loadable(
  lazy(() => import("pages/apps/question/list"))
);
const AppShortAnswer = Loadable(
  lazy(() => import("pages/apps/question/shortAnswer"))
);
const AppMCQQuestion = Loadable(lazy(() => import("pages/apps/question/mcq")));
const AppMCMCQuestion = Loadable(
  lazy(() => import("pages/apps/question/mcmc"))
);
const AppProcedureShortAns = Loadable(
  lazy(() => import("pages/apps/procedure/shortAnswerProcedue"))
);
const StandardList = Loadable(
  lazy(() => import("pages/apps/Standards/StandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/apps/Standards/StandardDetails"))
);

const QualityManualList = Loadable(
  lazy(() =>
    import("pages/apps/Standards/QMSSet/QualityManual/QualityManualList")
  )
);
const AddEditManual = Loadable(
  lazy(() => import("pages/apps/Standards/QMSSet/QualityManual/AddEditManual"))
);
const ProcedureList = Loadable(
  lazy(() => import("pages/apps/Standards/QMSSet/Procedure/ProcedureList"))
);
const AddEditProcedure = Loadable(
  lazy(() => import("pages/apps/Standards/QMSSet/Procedure/AddEditProcedure"))
);

const FormatList = Loadable(
  lazy(() => import("pages/apps/Standards/QMSSet/Format/FormatList"))
);

const DesignationChart = Loadable(
  lazy(() =>
    import("pages/apps/Standards/QMSSet/QualityManual/DesignationChart")
  )
);
const HeaderField = Loadable(
  lazy(() => import("pages/apps/Standards/QMSSet/QualityManual/HeaderField"))
);
const ShortQuestion = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/ShortQuestion"))
);
const McqQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McqQuestions"))
);
const McmcQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McmcQuestions"))
);
const QuestionList = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/QuestionList"))
);
const DropdownQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/DropdownQuestions"))
);

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("pages/extra-pages/sample-page"))
);

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "client",
          children: [
            {
              path: "client-list",
              element: <AppClientList />,
            },
            {
              path: "products",
              element: <AppClientProductList />,
            },
          ],
        },
        {
          path: "product",
          children: [
            {
              path: "product-list",
              element: <AppProductList />,
            },
            {
              path: "document",
              element: <AppProductDocument />,
            },
          ],
        },
        {
          path: "category",
          children: [
            {
              path: "category-list",
              element: <AppCategoryList />,
            },
          ],
        },
        {
          path: "template",
          children: [
            {
              path: "template-list",
              element: <AppTemplateList />,
            },
            {
              path: "add-template",
              element: <AppTemplateAdd />,
            },
            {
              path: "edit-template",
              element: <AppTemplateEdit />,
            },
            {
              path: "view-template",
              element: <AppTemplateView />,
            },
          ],
        },
        {
          path: "question",
          children: [
            {
              path: "question-list",
              element: <AppQuestionList />,
            },
            {
              path: "short-question",
              element: <AppShortAnswer />,
            },
            {
              path: "edit-short-answer",
              element: <AppShortAnswer />,
            },
            {
              path: "mcq-question",
              element: <AppMCQQuestion />,
            },
            {
              path: "edit-mcq-question",
              element: <AppMCQQuestion />,
            },
            {
              path: "mcmc-question",
              element: <AppMCMCQuestion />,
            },
            {
              path: "edit-mcmc-question",
              element: <AppMCMCQuestion />,
            },
          ],
        },
        {
          path: "procedure",
          children: [
            {
              path: "short-answer",
              element: <AppProcedureShortAns />,
            },
          ],
        },
        {
          path: "standards",
          children: [
            {
              path: "standard-list",
              element: <StandardList />,
            },
            {
              path: "standard-details",
              element: <StandardDetails />,
            },
            {
              path: "quality-manual",
              element: <QualityManualList />,
            },
            {
              path: "procedure",
              element: <ProcedureList />,
            },
            {
              path: "format",
              element: <FormatList />,
            },
            {
              path: "addEdit-manual",
              element: <AddEditManual />,
            },
            {
              path: "addEdit-procedure",
              element: <AddEditProcedure />,
            },
            { path: "designation-chart", element: <DesignationChart /> },
            { path: "header-field", element: <HeaderField /> },
            { path: "themes", element: <Theme /> },
            { path: "questions", element: <QuestionStandardList /> },
          ],
        },
        {
          path: "documentQuestion",
          children: [
            {
              path: "mcq-question",
              element: <McqQuestions />,
            },
            {
              path: "mcmc-question",
              element: <McmcQuestions />,
            },
            {
              path: "short-question",
              element: <ShortQuestion />,
            },
            {
              path: "allQuestions",
              element: <QuestionList />,
            },
            {
              path: "dropdown-question",
              element: <DropdownQuestions />,
            },
          ],
        },
      ],
    },
    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;
