import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Grid,
  CardMedia,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";

// assets
import { ThemeMode } from "config";
import { ApiServices } from "services/apiServices";
import { UPDATE_QM } from "services/url_helper";
import { dispatchFunction } from "services/utils";

// ==============================|| THEME||============================== //

const Theme = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const importAll = (r) => r.keys().map(r);
  const images = importAll(
    require.context("assets/doc-theme/", false, /\.(png|jpe?g|svg)$/)
  );
  const themeImages = [...new Set(images)];
  const [selectedTheme, setSelectedTheme] = useState(state.theme ?? "");
  const getThemeName = (name) => {
    const regex = /([^/]+)\.\w+$/;
    const match = name.match(regex);
    const finalName = match[1].split(".");
    return finalName[0];
  };

  const handleTheme = async (item) => {
    try {
      let reqObj = {
        selectedTheme: getThemeName(item),
        standardName: state.standardName,
      };
      let uri = UPDATE_QM + state.manualId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      dispatchFunction(result);
    } catch (error) {
      console.error("UPDATE_QM FUNCTION ERROR", error);
    }
  };

  const getThemeData = async () => {
    try {
      let reqObj = {
        field: "selectedTheme",
      };
      let uri = UPDATE_QM + state.manualId;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result.response) setSelectedTheme(result.selectedTheme);
    } catch (error) {
      console.error("UPDATE_QM ERROR", error);
    }
  };
  useEffect(() => {
    selectedTheme && getThemeData();
  }, [selectedTheme]);

  return (
    <MainCard title={"Select Theme"}>
      <ScrollX>
        <Grid container spacing={3}>
          {themeImages.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <MainCard
                content={false}
                sx={{
                  "&:hover": {
                    transform: "scale3d(1.02, 1.02, 1)",
                    transition: "all .4s ease-in-out",
                  },
                }}
              >
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="size"
                          defaultValue="small"
                          name="radio-buttons-group"
                          row
                        >
                          <FormControlLabel
                            control={<Radio className="size-large" />}
                            onChange={(e) => {
                              setSelectedTheme(item);
                              handleTheme(item);
                            }}
                            checked={
                              selectedTheme && item.includes(selectedTheme)
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      <Typography
                        color="textPrimary"
                        variant="h5"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                          textDecoration: "none",
                          marginTop: 1,
                        }}
                      >
                        {getThemeName(item)}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      marginLeft={"3rem"}
                    >
                      {"Theme"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <CardMedia
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 240,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item}
                    alt="Theme Image"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </CardMedia>
                <Divider />
              </MainCard>
            </Grid>
          ))}
        </Grid>
      </ScrollX>
    </MainCard>
  );
};

Theme.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  message: PropTypes.string,
  templateName: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number,
};

export default Theme;
