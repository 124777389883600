// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";

// assets
import { Award, Profile2User } from "iconsax-react";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { useEffect } from "react";
import EcommerceMetrix from "components/cards/statistics/EcommerceMetrix";
import MainCard from "components/MainCard";
import { useNavigate } from "react-router";
// import WelcomeBanner from 'sections/dashboard/default/WelcomeBanner';

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    getClients();
    getAllStandards();
  }, []);

  const getClients = () => {
    try {
      let uri = url.GET_ALL_CLIENT;
      ApiServices.callServiceGet(uri)
        .then((response) => {
          if (response?.result.type === "success") {
            setClientCount(response.result.data.length);
          }
        })
        .catch((error) => {
          console.error("GET_ALL_CLIENT call Error", error);
        });
    } catch (error) {
      console.error("GET_ALL_CLIENT_API Function Error", error);
    }
  };

  const getAllStandards = async () => {
    try {
      let uri = url.ADD_STANDARD;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.result?.type == "success")
        setStandardsCounts(result.result.data.length);
    } catch (error) {
      console.error("Error fetching standards:", error);
    }
  };

  const handleClick = async (itm) => {
    navigate(itm);
  };

  return (
    <MainCard title="Dashboard">
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          lg={4}
          sm={6}
          onClick={(e) => handleClick("/client/client-list")}
        >
          <EcommerceMetrix
            secondary="Clients"
            content="Total Client 5"
            color={theme.palette.primary.main}
            iconPrimary={Profile2User}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={4}
          sm={6}
          onClick={(e) => handleClick("/standards/standard-list")}
        >
          <EcommerceMetrix
            secondary="Standards"
            content="Total Client 5"
            color={theme.palette.warning.main}
            iconPrimary={Award}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Dashboard;
